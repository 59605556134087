import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { CONSTANT, checkLoginFromLogin, getRole } from "../CONSTANT";
import { Eye, EyeSlash, Lock } from "phosphor-react";

const Login = () => {
  let navigate = useNavigate();

  useEffect(() => {
    if (checkLoginFromLogin()) {
      let role = getRole();
      navigate("/" + (role === "readonly" ? "parliamentarian" : role));
    }
  }, []);

  const __init = {
    password: "",
  };
  const [credentials, setCredentials] = useState(__init);

  const changeCredentials = (e) => {
    if (document.getElementById("login_button").innerText !== "Log In") {
      document.getElementById("login_button").innerText = "Log In";
    }
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  // Hide and show password
  const [showPassword, setshowPassword] = useState(false);

  const login = async (e) => {
    e.target.style.pointerEvents = "none";
    e.target.innerHTML =
      '<div className="spinner-border custom-spin" role="status"><span className="visually-hidden">Loading...</span></div>';
    e.preventDefault();
    if (credentials.password !== "") {
      await axios
        .post(CONSTANT.server + "credentials/validate", credentials)
        .then((response) => {
          if (response.data) {
            if (response.data.message) {
              e.target.style.pointerEvents = "unset";
              e.target.innerHTML = "Not Registered";
            } else {
              sessionStorage.setItem(
                "loggedin",
                JSON.stringify({
                  data: {
                    _id: response.data._id,
                    name: response.data.name,
                    role: response.data.role,
                    addedAt: response.data.addedAt,
                  },
                })
              );
              navigate(
                "/" +
                  (response.data.role === "readonly"
                    ? "parliamentarian"
                    : response.data.role)
              );
              e.target.style.pointerEvents = "unset";
              e.target.innerHTML = "Log In";
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Dummy Login Passwords for testing purposes
  const handleLogin = () => {
    if (credentials.password == "mic") {
      navigate("/mic");
    } else if (credentials.password == "admin") {
      navigate("/admin");
    } else if (credentials.password == "parliamentarian") {
      navigate("/parliamentarian");
    } else {
      return;
    }
  };
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      className=" flex items-center justify-center w-screeen h-screen  bg-_dark"
    >
      <div className="container mx-auto p-4 space-y-6">
        <h1 className="text-white text-[52px] font-extrabold">Welcome</h1>
        <p className="font-light text-white text-[28px]">
          PRQ Parliamentary Recognition <br /> Queue System
        </p>
        <form>
          <div className="flex flex-col w-[468px] mx-auto">
            <p className="text-left text-white mb-1">Password</p>
            <div className="relative mb-5">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <Lock
                  className="w-5 h-5 font-light text-_gray "
                  weight="fill"
                />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={credentials.password}
                onChange={changeCredentials}
                className="block p-4 pl-10 w-full text-sm outline-none text-gray-900 bg-gray-50 rounded-full border border-gray-300 focus:ring-gray-500 focus:border-gray-700 text-[16px]  h-[43px]  _box_shadow"
                placeholder="Password "
                required
              />
              <div
                className="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer "
                onClick={() => setshowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Eye className="w-5 h-5 font-light text-_gray" />
                ) : (
                  <EyeSlash className="w-5 h-5 font-light text-_gray" />
                )}
              </div>
            </div>
            <button
              className={`block w-full text-[18px]  font-extrabold outline-none text-white bg-_gold rounded-full border border-_gold focus:ring-_gold focus:border-_gold h-[43px] _box_shadow cursor-${
                credentials.password !== ""
                  ? "pointer"
                  : "not-allowed opacity-50"
              }`}
              onClick={login}
              id="login_button"
              disabled={credentials.password !== "" ? false : true}
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default Login;
