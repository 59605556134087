import React, { useState, useEffect, useContext, useRef } from "react";
import AdminRow from "../components/AdminRow";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANT, checkLoginFromAdmin } from "../CONSTANT";
import axios from "axios";
import openSocket from "socket.io-client";
import UserData from "../contexts/UserData";
import {
  CaretLeft,
  CaretRight,
  CaretUp,
  CaretDown,
  Calendar,
  MicrophoneStage,
  Funnel,
} from "phosphor-react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
const Admin = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [SOCKET, SETSOCKET] = useState(null);
  const { session, setSession } = useContext(UserData);
  let navigate = useNavigate();

  useEffect(() => {
    if (checkLoginFromAdmin()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    fetchAllRecordsData();
  }, []);

  const fetchAllRecordsData = async () => {
    await axios
      .get(CONSTANT.server + "records/view")
      .then((responce) => {
        setMicsData(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [micsData, setMicsData] = useState([]);
  const [isAddNote, setIsAddNote] = useState(false);
  const [adminViewNote, setAdminViewNote] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    threshold: 15,
  });

  // setup admin notes data for modal
  function todayDateFormat(payload = "", isRow = false) {
    let suffix = ["st", "nd", "rd", "th"];
    let date = new Date();
    if (payload !== "") {
      date = new Date(payload);
    }
    let options = {
      month: "long",
      year: "numeric",
    };
    if (isRow) {
      options = {
        ...options,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
    }
    let dateString = date.toLocaleString("en-US", options);

    const day = date.getDate();
    return `${day}${suffix[day < 4 ? day - 1 : 3]} ${dateString}`;
  }

  function sortList(list, orderPOS) {
    const order = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
      G: 7,
      H: 8,
      I: 9,
      J: 10,
      K: 11,
      L: 12,
      M: 13,
      N: 14,
      O: 15,
      P: 16,
      Q: 17,
      R: 18,
      S: 19,
      T: 20,
      U: 21,
      V: 22,
      W: 23,
      X: 24,
      Y: 25,
      Z: 26,
    };

    list.sort((a, b) => {
      const [aNum, aLetter] = [parseInt(a.position), a.position.slice(-1)];
      const [bNum, bLetter] = [parseInt(b.position), b.position.slice(-1)];

      if (aNum !== bNum) {
        if (orderPOS === 0) {
          return aNum - bNum;
        }
        return bNum - aNum; // Sort descending by number
      }
      if (orderPOS === 0) {
        return (order[bLetter] || 0) - (order[aLetter] || 0);
      }
      return (order[aLetter] || 0) - (order[bLetter] || 0);
    });

    return list;
  }

  function extractPosition(str) {
    const prefix = "mic ";
    if (str.startsWith(prefix)) {
      return str.substring(prefix.length);
    } else {
      return str;
    }
  }

  const [viewNoteMessage, setViewNoteMessage] = useState({
    date: todayDateFormat(),
    msg: "",
  });

  // On pressing the view icon of admin notes row, it will setup the date and message of that particular note into the modal window

  const handleAdminNoteViewButton = (msg, date = "") => {
    // set admin view note to true in order to show the date and message
    setAdminViewNote(true);
    setViewNoteMessage({
      date: todayDateFormat(date),
      msg: msg,
      readOnly: true,
    });
    // assigning the date and message of the modal

    // open the modal window having date and message of that particular admin note
    setIsAddNote(true);
  };

  // Handle admin note on click of save button of modal
  const handleNoteSaveButton = async (msg) => {
    // some logic for handling the message of the admin note after pressing the save button of modal windows
    await axios
      .post(CONSTANT.server + "records/insert", {
        isNote: true,
        note: msg ?? viewNoteMessage.msg,
      })
      .then((responce) => {
        fetchAllRecordsData();
      })
      .catch((error) => {
        console.log(error);
      });
    // Closing the add note modal window
    setIsAddNote(false);
  };

  // ========
  // SOCKET IO START
  // ========

  const socketConnection = useRef(null);

  useEffect(() => {
    SETSOCKET(openSocket(CONSTANT.server));
  }, []);

  useEffect(() => {
    if (session.isLoggedIn && SOCKET !== null) {
      SOCKET.on("connect", () => {
        socketConnection.current = SOCKET.id;
        SOCKET.emit("ADMIN_REGISTER", {
          socket_id: SOCKET.id,
        });
        if (SOCKET.disconnected) {
          SOCKET.connect();
        }
      });
      // Connect to socket

      SOCKET.on("disconnect", () => {
        socketConnection.current = "";
      });
      // Connect and update socket id on state

      SOCKET.on("ADMIN_UPDATE", (payload) => {
        fetchAllRecordsData();
      });
      // TAKE UPDATES

      return () => {
        SOCKET.disconnect();
      };
      // When leaving, inform server
    }
  }, [session, SOCKET]);

  // ========
  // SOCKET IO END
  // ========

  // Filters

  const [finalData, setFinalData] = useState([...micsData]);

  const __INIT_FILTER__ = {
    date: [
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ],
    position: [],
    level: [],
    reason: [],
    spoke: [],
    reverse: false,
    note: "cn",
    orderWRT: "addedAt",
    orderPOS: 1, // 1 for asc and 0 for desc
  };

  const [filter, setFilter] = useState(__INIT_FILTER__);

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 1,
    });
  }, [filter]);

  const [dateFilterOpener, setDateFilterOpener] = useState(false);
  const [micFilterOpener, setMicFilterOpener] = useState(false);
  const [levelFilterOpener, setLevelFilterOpener] = useState(false);
  const [reasonFilterOpener, setReasonFilterOpener] = useState(false);
  const [spokeFilterOpener, setSpokeFilterOpener] = useState(false);

  const addToFilter = (val, setter) => {
    setFilter({
      ...filter,
      [setter]: [...filter[setter], val],
    });
  };
  const removeToFilter = (val, setter) => {
    setFilter({
      ...filter,
      [setter]: [
        ...filter[setter]
          .filter((a, b) => {
            return a !== val;
          })
          .map((a, b) => {
            return a;
          }),
      ],
    });
  };

  function compareStrings(a, b, order) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    if (order === 0) {
      return a < b ? -1 : a > b ? 1 : 0;
    }
    return a < b ? 1 : a > b ? -1 : 0;
  }

  useEffect(() => {
    let temp = micsData.map((a, b) => {
      return {
        ...a,
        position: extractPosition(a.position),
      };
    });
    if (filter.reverse) {
      temp = micsData.reverse();
    }

    temp = temp
      .filter((position, index) => {
        return (
          ((filter.position.length > 0 &&
            filter.position.includes(position.position.slice(0, -1))) ||
            filter.position.length === 0) &&
          ((filter.level.length > 0 && filter.level.includes(position.level)) ||
            filter.level.length === 0) &&
          ((filter.reason.length > 0 &&
            filter.reason.includes(position.reason)) ||
            filter.reason.length === 0) &&
          ((filter.spoke.length > 0 &&
            filter.spoke.includes(position.hasSpoke ? "yes" : "no")) ||
            filter.spoke.length === 0) &&
          ((filter.date[0].startDate !== null &&
            new Date(position.addedAt) >= new Date(filter.date[0].startDate)) ||
            filter.date[0].startDate === null) &&
          ((filter.date[0].endDate !== null &&
            new Date(position.addedAt) < new Date(filter.date[0].endDate)) ||
            filter.date[0].endDate === null) &&
          ((filter.note !== "cn" &&
            position.isNote === (filter.note === "n" ? true : false)) ||
            filter.note === "cn")
        );
      })
      .sort(function (a, b) {
        if (filter.orderWRT === "" || filter.orderWRT === "position") {
          return 0;
        }
        return compareStrings(
          filter.orderWRT === "hasSpoke"
            ? a[filter.orderWRT]
              ? "yes"
              : "no"
            : a[filter.orderWRT],
          filter.orderWRT === "hasSpoke"
            ? b[filter.orderWRT]
              ? "yes"
              : "no"
            : b[filter.orderWRT],
          filter.orderPOS
        );
      });
    setFinalData(
      filter.orderWRT === "position" ? sortList(temp, filter.orderPOS) : temp
    );
  }, [filter, micsData]);

  const resetFilters = () => {
    setFilter(__INIT_FILTER__);
    setPagination({
      page: 1,
      threshold: 15,
    });
  };

  return (
    <div className="spacing">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start ">
          <input
            type="number"
            className="w-9 text-center m-0 p-0 numberHide  "
            value={pagination.threshold}
            onChange={(e) => {
              setPagination({
                ...pagination,
                threshold: parseInt(e.target.value),
                page: 1,
              });
            }}
          />
          <p className="mr-2"> per page</p>
          <div className="flex items-center space-x-2">
            <CaretLeft
              className={`cursor-pointer ${
                !(pagination.page > 1) ? "opacity-50" : ""
              }`}
              onClick={() => {
                if (pagination.page > 1) {
                  setPagination({
                    ...pagination,
                    page: pagination.page - 1,
                  });
                }
              }}
            />
            <span className="">{pagination.page}</span>
            <CaretRight
              className={`cursor-pointer ${
                pagination.page ===
                Math.ceil(finalData.length / pagination.threshold)
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => {
                if (
                  pagination.page <
                  Math.ceil(finalData.length / pagination.threshold)
                ) {
                  setPagination({
                    ...pagination,
                    page: pagination.page + 1,
                  });
                }
              }}
            />
          </div>
          {/* <p className="ml-3"> - </p>
          <select
            value={filter.reverse}
            onChange={(e) =>
              setFilter({
                ...filter,
                reverse: e.target.value,
              })
            }
            className="text-gray-900 text-sm rounded-lg block p-2.5"
          >
            <option value={false}>Newest</option>
            <option value={true}>Oldest</option>
          </select>{" "} */}
          <p className="ml-3"> - </p>
          <select
            value={filter.note}
            onChange={(e) =>
              setFilter({
                ...filter,
                note: e.target.value,
              })
            }
            className="text-gray-900 text-sm rounded-lg block p-2.5"
          >
            <option value={"cn"}>Calls + Notes</option>
            <option value={"c"}>Calls</option>
            <option value={"n"}>Notes</option>
          </select>
          <p className="ml-3"> - </p>
          <p
            className="ml-3 text-[#F3514F] cursor-pointer"
            onClick={resetFilters}
          >
            {" "}
            Reset Filters
          </p>
        </div>
        <button
          className="border-2 border-[#D9AF57] rounded-full w-[123px] h-[39px] flex items-center justify-center  py-[7px] px-[11px] font-extrabold text-[#D9AF57] my-1"
          onClick={() => {
            setViewNoteMessage({
              date: todayDateFormat(),
              msg: "",
            });
            setIsAddNote(true);
          }}
        >
          {" "}
          Add Note
        </button>
      </div>

      {isAddNote && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-[612px] h-[392px] rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">
              {adminViewNote ? "Note" : "Add note"}
              {" for " + viewNoteMessage.date}
            </h1>

            <textarea
              placeholder="Start Writing"
              readOnly={viewNoteMessage?.readOnly !== undefined}
              className="border w-full  rounded-[16px] h-[214px] p-4"
              value={viewNoteMessage.msg}
              id="textAreaForNotes"
              onChange={(e) =>
                setViewNoteMessage({
                  ...viewNoteMessage,
                  msg: e.target.value,
                })
              }
            />

            {viewNoteMessage?.readOnly === undefined ? (
              <div className="my-3 flex justify-start">
                <span
                  onClick={() => {
                    // handleNoteSaveButton("Session has started!");
                    setViewNoteMessage({
                      ...viewNoteMessage,
                      msg: "Session has started.",
                    });
                    setTimeout(function () {
                      document.getElementById("textAreaForNotes").focus();
                    }, 0);
                  }}
                  class="cursor-pointer bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400"
                >
                  Session has started.
                </span>
                <span
                  onClick={() => {
                    // handleNoteSaveButton("Session has ended!");
                    setViewNoteMessage({
                      ...viewNoteMessage,
                      msg: "Session has ended.",
                    });
                    setTimeout(function () {
                      document.getElementById("textAreaForNotes").focus();
                    }, 0);
                  }}
                  class="cursor-pointer bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400"
                >
                  Session has ended.
                </span>
              </div>
            ) : null}
            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setIsAddNote(false)}
              >
                Cancel
              </button>
              {viewNoteMessage?.readOnly === undefined ? (
                <button
                  className="w-[186px] h-[48px] bg-[#D9AF57] rounded-[49px] text-white font-extrabold cursor-pointer"
                  onClick={() => handleNoteSaveButton()}
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {dateFilterOpener && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-[80%] h-[80%] rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">Date Filter</h1>
            <div className="flex justify-center">
              <DateRangePicker
                onChange={(item) =>
                  setFilter({
                    ...filter,
                    date: [item.selection],
                  })
                }
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={filter.date}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                className="w-full flex justify-center"
              />
            </div>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setDateFilterOpener(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {micFilterOpener && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-fit h-fit rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">
              Mic Filter{" "}
              <span className="text-[16px]">
                ({filter.position.length === 0 ? "All" : filter.position.length}{" "}
                selected)
              </span>
            </h1>
            <div className="flex justify-center items-center w-full">
              <div className="w-[90%] flex flex-wrap justify-start items-start">
                {[
                  ...new Set(
                    micsData
                      .map((a, b) => {
                        return {
                          ...a,
                          position: extractPosition(a.position),
                        };
                      })
                      .filter((a, i) => {
                        if (a.position === "") {
                          return false;
                        }
                        return true;
                      })
                      .map((a, i) => {
                        return a.position.slice(0, -1);
                      })
                  ),
                ].map((a, i) => {
                  return (
                    <span
                      onClick={() => {
                        if (filter.position.includes(a)) {
                          removeToFilter(a, "position");
                        } else {
                          addToFilter(a, "position");
                        }
                      }}
                      className={`border-${
                        filter.position.includes(a) ? "green" : "blue"
                      }-400 bg-${
                        filter.position.includes(a) ? "green" : "blue"
                      }-100 text-${
                        filter.position.includes(a) ? "green" : "blue"
                      }-800 cursor-pointer w-fit mx-2 my-1 text-xs font-medium px-2.5 py-0.5 rounded border`}
                    >
                      Mic {a}
                    </span>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setMicFilterOpener(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {levelFilterOpener && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-fit h-fit rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">
              Level Filter{" "}
              <span className="text-[16px]">
                ({filter.level.length === 0 ? "All" : filter.level.length}{" "}
                selected)
              </span>
            </h1>
            <div className="flex justify-center items-center w-full">
              <div className="w-[90%] flex flex-wrap justify-start items-start">
                {[
                  ...new Set(
                    micsData
                      .filter((a, i) => {
                        if (a.level === "") {
                          return false;
                        }
                        return true;
                      })
                      .map((a, i) => {
                        return a.level;
                      })
                  ),
                ].map((a, i) => {
                  return (
                    <span
                      onClick={() => {
                        if (filter.level.includes(a)) {
                          removeToFilter(a, "level");
                        } else {
                          addToFilter(a, "level");
                        }
                      }}
                      className={`border-${
                        filter.level.includes(a) ? "green" : "blue"
                      }-400 bg-${
                        filter.level.includes(a) ? "green" : "blue"
                      }-100 text-${
                        filter.level.includes(a) ? "green" : "blue"
                      }-800 cursor-pointer w-fit mx-2 my-1 text-xs font-medium px-2.5 py-0.5 rounded border`}
                    >
                      Level {a}
                    </span>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setLevelFilterOpener(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {reasonFilterOpener && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-fit h-fit rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">
              Reason Filter{" "}
              <span className="text-[16px]">
                ({filter.reason.length === 0 ? "All" : filter.reason.length}{" "}
                selected)
              </span>
            </h1>
            <div className="flex justify-center items-center w-full">
              <div className="w-[90%] flex flex-wrap justify-start items-start">
                {[
                  ...new Set(
                    micsData
                      .filter((a, i) => {
                        if (a.reason === "") {
                          return false;
                        }
                        return true;
                      })
                      .map((a, i) => {
                        return a.reason;
                      })
                  ),
                ].map((a, i) => {
                  return (
                    <span
                      onClick={() => {
                        if (filter.reason.includes(a)) {
                          removeToFilter(a, "reason");
                        } else {
                          addToFilter(a, "reason");
                        }
                      }}
                      className={`border-${
                        filter.reason.includes(a) ? "green" : "blue"
                      }-400 bg-${
                        filter.reason.includes(a) ? "green" : "blue"
                      }-100 text-${
                        filter.reason.includes(a) ? "green" : "blue"
                      }-800 cursor-pointer w-fit mx-2 my-1 text-xs font-medium px-2.5 py-0.5 rounded border`}
                    >
                      {a === "general"
                        ? "General/For"
                        : a === "against"
                        ? "General/Against"
                        : a === "request"
                        ? "Request"
                        : a === "urgent"
                        ? "Urgent Recognition"
                        : ""}
                    </span>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setReasonFilterOpener(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {spokeFilterOpener && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-fit h-fit rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] mb-2">
              Spoke Filter{" "}
              <span className="text-[16px]">
                ({filter.spoke.length === 0 ? "All" : filter.spoke.length}{" "}
                selected)
              </span>
            </h1>
            <div className="flex justify-center items-center w-full">
              <div className="w-[90%] flex flex-wrap justify-start items-start">
                {[
                  ...new Set(
                    micsData.map((a, i) => {
                      return a.hasSpoke;
                    })
                  ),
                ].map((a, i) => {
                  return (
                    <span
                      onClick={() => {
                        if (filter.spoke.includes(a ? "yes" : "no")) {
                          removeToFilter(a ? "yes" : "no", "spoke");
                        } else {
                          addToFilter(a ? "yes" : "no", "spoke");
                        }
                      }}
                      className={`border-${
                        filter.spoke.includes(a ? "yes" : "no")
                          ? "green"
                          : "blue"
                      }-400 bg-${
                        filter.spoke.includes(a ? "yes" : "no")
                          ? "green"
                          : "blue"
                      }-100 text-${
                        filter.spoke.includes(a ? "yes" : "no")
                          ? "green"
                          : "blue"
                      }-800 cursor-pointer w-fit mx-2 my-1 text-xs font-medium px-2.5 py-0.5 rounded border`}
                    >
                      {a ? "Yes" : "No"}
                    </span>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setSpokeFilterOpener(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-4">
        <div className="flex  items-center justify-between space-x-16 text-left border-b-2 px-2 py-1 border-gray-50 text-_dark font-bold">
          <div className="flex flex-row items-center w-[25%]">
            Date
            <span className=" flex flex-row items-center">
              <Calendar
                className="cursor-pointer w-[22px] h-[22px] ml-2"
                onClick={() => {
                  setDateFilterOpener(true);
                }}
              />
              {filter.orderWRT === "addedAt" && filter.orderPOS === 0 ? (
                <CaretUp
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "addedAt",
                      orderPOS: 1,
                    });
                  }}
                  className="cursor-pointer w-[18px] h-[18px] ml-2"
                />
              ) : (
                <CaretDown
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "addedAt",
                      orderPOS: 0,
                    });
                  }}
                  className="cursor-pointer w-[18px] h-[18px] ml-2"
                />
              )}
            </span>
          </div>

          <div className="w-[15%] flex flex-row items-center">
            Mic/Pos
            <span className="flex flex-row items-center">
              {/* <MicrophoneStage className="w-[22px] h-[22px] ml-2" /> */}

              <Funnel
                className="w-[22px] h-[22px] ml-2 cursor-pointer"
                onClick={() => {
                  setMicFilterOpener(true);
                }}
              />
              {filter.orderWRT === "position" && filter.orderPOS === 0 ? (
                <CaretUp
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "position",
                      orderPOS: 1,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              ) : (
                <CaretDown
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "position",
                      orderPOS: 0,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              )}
            </span>
          </div>

          <div className="w-[15%] flex flex-row items-center">
            Level
            <span className="flex flex-row items-center">
              <Funnel
                className="w-[22px] h-[22px] ml-2 cursor-pointer"
                onClick={() => {
                  setLevelFilterOpener(true);
                }}
              />
              {filter.orderWRT === "level" && filter.orderPOS === 0 ? (
                <CaretUp
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "level",
                      orderPOS: 1,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              ) : (
                <CaretDown
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "level",
                      orderPOS: 0,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              )}
            </span>
          </div>

          <div className="w-[30%] flex flex-row items-center">
            Reason
            <span className="flex flex-row items-center">
              <Funnel
                className="w-[22px] h-[22px] ml-2 cursor-pointer"
                onClick={() => {
                  setReasonFilterOpener(true);
                }}
              />
              {filter.orderWRT === "reason" && filter.orderPOS === 0 ? (
                <CaretUp
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "reason",
                      orderPOS: 1,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              ) : (
                <CaretDown
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "reason",
                      orderPOS: 0,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              )}
            </span>
          </div>
          <div className="w-[15%] flex flex-row items-center">
            Spoke
            <span className="flex flex-row items-center">
              <Funnel
                className="w-[22px] h-[22px] ml-2 cursor-pointer"
                onClick={() => {
                  setSpokeFilterOpener(true);
                }}
              />
              {filter.orderWRT === "hasSpoke" && filter.orderPOS === 0 ? (
                <CaretUp
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "hasSpoke",
                      orderPOS: 1,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              ) : (
                <CaretDown
                  onClick={() => {
                    setFilter({
                      ...filter,
                      orderWRT: "hasSpoke",
                      orderPOS: 0,
                    });
                  }}
                  className="w-[18px] h-[18px] ml-2 cursor-pointer"
                />
              )}
            </span>
          </div>
        </div>

        <div
          className="overflow-y-scroll h-full 
            max-h-[calc(100vh-200px)] "
        >
          {finalData
            .slice(
              pagination.threshold * (pagination.page - 1),
              pagination.threshold * pagination.page
            )
            .map((position, index) => {
              return (
                <AdminRow
                  key={index}
                  data={position}
                  handleAdminNoteViewButton={handleAdminNoteViewButton}
                  todayDateFormat={todayDateFormat}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Admin;
