import { Eye, ThumbsDown, ThumbsUp, Warning, Info } from "phosphor-react";
import React, { useState } from "react";

const AdminRow = (props) => {
  // Shows if president gave them the word or not.
  const displaySpoke = (hasSpoke) => {
    if (hasSpoke) {
      return (
        <div className="mx-auto  py-[2x] px-[4px] flex items-center justify-center w-[56px] h-[26px] bg-[#E9FAEA] border rounded-[6px] border-[#5FD86A]">
          Yes
        </div>
      );
    } else {
      return (
        <div className=" mx-auto py-[2x] px-[4px] flex items-center justify-center w-[56px] h-[26px] bg-[#FDE7E6] border rounded-[6px] border-[#F3514F]">
          No
        </div>
      );
    }
  };

  // Display Reason title and icon of row according to motive of participation request

  const displayReason = (reason) => {
    reason = reason.toLowerCase();
    if (reason === "general") {
      return (
        <>
          <ThumbsUp
            className="w-[15px] h-[15px] text-[#5fd86a]"
            weight="fill"
          />
          <div className="text-_dark text-[14px] font-bold   1 text-center ">
            General/For
          </div>
        </>
      );
    } else if (reason === "against") {
      return (
        <>
          <ThumbsDown
            className="w-[15px] h-[15px] text-[#F3514F]"
            weight="fill"
          />

          <div className="text-_dark text-[14px] font-bold   1 text-center ">
            General/Against
          </div>
        </>
      );
    } else if (reason === "request") {
      return (
        <>
          <Info className="w-[15px] h-[15px] text-[#3F607E]" weight="fill" />

          <div className="text-_dark text-[14px] font-bold   1 text-center ">
            Request
          </div>
        </>
      );
    } else if (reason === "urgent") {
      return (
        <>
          <Warning className="w-[15px] h-[15px] text-[#FFD600]" weight="fill" />

          <div className="text-_dark text-[14px] font-bold   1 text-center ">
            Urgent Recognition
          </div>
        </>
      );
    }
  };
  return (
    <>
      {/* Will display admin note row to the table if any otherwise will display mic history*/}
      {props?.data?.isNote ? (
        <div className="space-x-16 text-left border-b-2 py-[6px] border-gray-50 flex items-center justify-between bg-[#F5F5F5] px-2">
          <div className=" w-[20%] text-_dark text-[12px]  font-light  ">
            {props.todayDateFormat(props?.data?.addedAt, true)}
          </div>

          <div className="w-[55%]  text-center italic">
            Admin notes: {props?.data?.note}
          </div>

          <div className=" w-[10%] ">
            <button
              className=" mx-auto py-[2x] px-[4px] flex items-center justify-center "
              onClick={() =>
                props.handleAdminNoteViewButton(
                  props?.data?.note,
                  props?.data?.addedAt
                )
              }
            >
              <Eye className="w-[22px] h-[15px] text-[#000000]" weight="fill" />{" "}
              <p className="text-[#000000] font-bold text-[16px]">See</p>
            </button>
          </div>
        </div>
      ) : (
        <div className="space-x-16 text-left border-b-2 py-[6px]  border-gray-50 flex items-center justify-between px-2">
          <div className=" w-[25%] text-_dark text-[12px]  font-light  ">
            {props.todayDateFormat(props?.data?.addedAt, true)}
          </div>

          <div className="text-center  w-[15%]  text-_dark text-[14px] font-light">
            {props?.data?.position}
          </div>

          <div className="text-center   w-[15%]  text-_dark text-[14px]">
            {parseInt(props?.data?.level) === 1 ? "" : props?.data?.level}
          </div>

          <div className="text-center  w-[30%] flex items-center space-x-1 ">
            {displayReason(props?.data?.reason)}
          </div>
          <div className="text-center w-[15%] ">
            {displaySpoke(props?.data?.hasSpoke)}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminRow;
