import React from "react";

const DynamicModal = (props) => {
   return (
      <div
         className="z-50 fixed top-[0px] left-0 w-screen h-screen flex items-center justify-center "
         style={{
            background: "rgba(0,0,0,0.2)",
         }}
      >
         <div
            className="p-8 relative  text-center bg-white w-[468px] space-y-4 rounded-[26px] flex flex-col justify-between "
            style={{
               boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
         >
            <h1 className="font-bold text-[28px]">{props.title}</h1>
            {props.desc && (
               <h1 className="font-light text-[20px]">{props.desc}</h1>
            )}

            <div className="flex justify-center items-center space-x-5 text-[20px] w-full">
               <button
                  className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                  onClick={() => {
                     props.setIsModal(false);
                  }}
               >
                  Cancel
               </button>
               <button
                  className="w-[186px] h-[44px] bg-[#D9AF57] rounded-[49px] text-white font-extrabold cursor-pointer"
                  onClick={() => {
                     props.func();
                  }}
               >
                  {props.btnText}
               </button>
            </div>
         </div>
      </div>
   );
};

export default DynamicModal;
