import {
  ArrowLeft,
  ArrowRight,
  Microphone,
  ThumbsUp,
  Warning,
  ThumbsDown,
  Info,
} from "phosphor-react";
import React, { useState, useEffect, useContext, useRef } from "react";
import DynamicModal from "../components/DynamicModal";
import MicParlimainterian from "../components/MicParlimainterian";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANT, checkLoginFromAdmin } from "../CONSTANT";
import openSocket from "socket.io-client";
import UserData from "../contexts/UserData";
import { v4 as uuidv4 } from "uuid";

const Parliamentarian = () => {
  const [SOCKET, SETSOCKET] = useState(null);
  const { session, setSession } = useContext(UserData);
  let navigate = useNavigate();

  useEffect(() => {
    if (checkLoginFromAdmin(true)) {
      navigate("/");
    }
  }, []);
  const [isModal, setIsModal] = useState(false);
  const [isUrgentMicrophone, setIsUrgentMicrophone] = useState(false);
  const [modalData, setModalData] = useState("");
  // { position: "20A", addedAt: 1674088212037 },
  // { position: "30B", addedAt: 1674088212137 },
  // { position: "40C", addedAt: 1674088213137 },
  // { position: "50D", addedAt: 1674088214137 },
  // { position: "60E", addedAt: 1674088215137 },
  // { position: "70F", addedAt: 1674088216137 },
  // { position: "80G", addedAt: 1674088217137 },
  // { position: "90H", addedAt: 1674088218137 },
  // { position: "100I", addedAt: 1674088219137 },
  // { position: "110J", addedAt: 1674088210137 },
  // { position: "120K", addedAt: 1674088211137 },
  const __INIT__ = [
    { length: 0, type: "request", mics: [] },
    { length: 0, type: "general", mics: [] },
    {
      length: 0,
      type: "against",
      mics: [],
    },
    {
      length: 0,
      type: "urgent",
      mics: [],
    },
  ];
  const [participantQueueData, setParticipantQueueData] = useState(__INIT__);

  const [buttonPressedFirst, setButtonPressedFirst] = useState({
    pressed: false,
    pressedBy: "", //pressed by could be request or for or against
  });

  useEffect(() => {
    let __INDEX__ = "";
    if (
      participantQueueData[1]?.mics?.length !== 0 &&
      participantQueueData[2]?.mics?.length !== 0
    ) {
      if (
        participantQueueData[1]?.mics[0]?.addedAt >
        participantQueueData[2]?.mics[0]?.addedAt
      ) {
        __INDEX__ = 2;
      } else {
        __INDEX__ = 1;
      }
    } else if (
      participantQueueData[1]?.mics?.length === 0 &&
      participantQueueData[2]?.mics?.length !== 0
    ) {
      __INDEX__ = 2;
    } else if (
      participantQueueData[2]?.mics?.length === 0 &&
      participantQueueData[1]?.mics?.length !== 0
    ) {
      __INDEX__ = 1;
    }

    setButtonPressedFirst({
      pressed: true,
      pressedBy:
        __INDEX__ === "" ? __INDEX__ : participantQueueData[__INDEX__].type,
    });

    if (participantQueueData[3].length !== 0) {
      setIsUrgentMicrophone(true);
    } else {
      setIsUrgentMicrophone(false);
    }
  }, [participantQueueData]);

  // @function used for Handle Modal
  const handleModal = (title, desc, btnText, handleFunction) => {
    setModalData({
      title,
      desc,
      btnText,
      handleFunction,
    });

    setIsModal(true);
  };

  // handle Confirmation of moving to level 2
  const handleConfirmationOfMovingLevel2 = () => {
    // some logic of moving to level 2
    setLevel((old) => {
      SOCKET.emit("UPDATE_LEVEL", {
        level: old + 1,
      });
      return old + 1;
    });
    // closing the clear all modal
    setIsModal(false);
  };

  // handle Confirmation of moving to level 2
  const handleConfirmationOfReturningLevel1 = () => {
    // some logic of returning to level 1
    setLevel((old) => {
      SOCKET.emit("UPDATE_LEVEL", {
        level: old - 1,
      });
      return old - 1;
    });
    // closing the clear all modal
    setIsModal(false);
  };

  const [isClear, setIsClear] = useState(false);
  useEffect(() => {
    if (isClear) {
      SOCKET.emit("CLEAR_EVERYTHING", true);
      setLevel(1);
      setParticipantQueueData(__INIT__);
      setIsClear(false);
    }
  }, [isClear]);

  //Clears the screen on parliamentarian AND MIC screens.
  const clearAll = () => {
    // some logic to clear all
    setIsClear(true);
    // closing the clear all modal
    setIsModal(false);
  };

  // @function for styling urgent recognition
  const theme = {
    mic: "bg-[#FFD600]",
    micIcon: "text-_dark",
    noMic: "bg-[#F6F6F6]",
    noMicIcon: "text-[#FFD600]",
  };

  const [level, setLevel] = useState(null);
  const [currentCall, setCurrentCall] = useState({
    position: "",
    reason: "",
    addedAt: "",
  });
  const [isCallChanged, setIsCallChanged] = useState(false);

  // Next function

  useEffect(() => {
    if (
      currentCall.position !== "" &&
      currentCall.reason !== "" &&
      isCallChanged
    ) {
      SOCKET.emit("ALLOW_TO_SPEAK", {
        ...currentCall,
      });
      setIsCallChanged(false);
    }
  }, [currentCall, isCallChanged]);

  const allowToSpeak = (payload) => {
    setCurrentCall({
      ...payload,
    });
    setIsCallChanged(true);
  };

  // useEffect(() => {
  //   if (SOCKET !== null && level !== null) {
  //     SOCKET.emit("UPDATE_LEVEL", {
  //       level: level,
  //     });
  //   }
  // }, [level, SOCKET]);

  // ========
  // SOCKET IO START
  // ========

  const socketConnection = useRef(null);

  useEffect(() => {
    SETSOCKET(openSocket(CONSTANT.server));
  }, []);

  useEffect(() => {
    if (session.isLoggedIn && SOCKET !== null) {
      SOCKET.on("connect", () => {
        socketConnection.current = SOCKET.id;
        SOCKET.emit("PARLIAMENTARIAN_REGISTER", {
          _id: session.personal._id,
          level: parseInt(level),
          socket_id: SOCKET.id,
        });
        if (SOCKET.disconnected) {
          SOCKET.connect();
        }
      });
      // Connect to socket

      SOCKET.on("disconnect", () => {
        socketConnection.current = "";
      });
      // Connect and update socket id on state

      SOCKET.on("PARLIAMENTARIAN_UPDATE", (payload) => {
        // console.log(payload);
        setParticipantQueueData(payload[0]);
        setLevel(payload[1]);
        setCurrentCall({
          ...payload[2],
        });
      });
      // TAKE UPDATES

      return () => {
        SOCKET.disconnect();
      };
      // When leaving, inform server
    }
  }, [session, SOCKET]);

  const clearQueues = (type) => {
    SOCKET.emit("CLEAR_QUEUE", {
      level: level,
      type: type,
    });
    setIsModal(false);
  };

  // ========
  // SOCKET IO END
  // ========

  // Test point start
  const [test, setTest] = useState(0);
  useEffect(() => {
    if (test !== 0) {
      SOCKET.emit("TEST", "");
    }
  }, [test]);
  // Test point end

  return (
    <>
      {isModal && (
        <DynamicModal
          title={modalData.title}
          desc={modalData.desc}
          btnText={modalData.btnText}
          setIsModal={setIsModal}
          func={modalData.handleFunction}
        />
      )}

      <div className="spacing mb-[10px]  flex flex-col justify-evenly   h-[calc(100vh-88px)]">
        <div className="flex items-end justify-between ">
          <div className="flex items-center space-x-2 w-[30%] text-_dark  mb-0 ">
            <p
              className="text-[16px] font-light"
              onClick={() => {
                setTest((old) => {
                  return old + 1;
                });
              }}
            >
              Speaking:
            </p>
            {currentCall.position !== "" ? (
              <>
                <div className="flex items-center space-x-1">
                  <Microphone weight="fill" />
                  <div className="font-bold text-[14px]">
                    Mic {currentCall.position}
                  </div>
                </div>
                <span className="font-bold">-</span>
                <div className="flex items-center space-x-1">
                  {currentCall.reason === "general" ? (
                    <ThumbsUp weight="fill" className="text-[#5FD86A]" />
                  ) : currentCall.reason === "against" ? (
                    <ThumbsDown className="text-[#F3514F]" weight="fill" />
                  ) : currentCall.reason === "request" ? (
                    <Info className="text-[#3F607E]" weight="fill" />
                  ) : currentCall.reason === "urgent" ? (
                    <Warning className="text-[#FFD600]" weight="fill" />
                  ) : (
                    ""
                  )}
                  <div className="font-bold text-[14px]">
                    {currentCall.reason === "general"
                      ? "General/For"
                      : currentCall.reason === "against"
                      ? "General/Against"
                      : currentCall.reason === "request"
                      ? "Request"
                      : currentCall.reason === "urgent"
                      ? "Urgent Recognition"
                      : ""}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-1">
                <Microphone weight="fill" />
                <div className="font-bold text-[14px]">None</div>
              </div>
            )}
          </div>

          <div
            className={`bg-[#E4E9ED]  w-[222px] h-[65px] px-[8px] py-[12px] gap-[6px] flex items-center justify-${
              session.personal.role === "readonly" ? "center" : "between"
            } rounded-[8px]`}
          >
            {session.personal.role !== "readonly" && (
              <div
                className={`w-[48px] h-[46px] -[8px] flex items-center justify-center border-2 border-[#3F607E] rounded-[6px] cursor-${
                  level === 1
                    ? "not-allowed opacity-50 pointer-events-none"
                    : "pointer"
                }`}
                onClick={() =>
                  handleModal(
                    `You are returning to Level ${level - 1}`,
                    "Queue will be resumed.",
                    "Confirm",
                    handleConfirmationOfReturningLevel1
                  )
                }
              >
                <ArrowLeft className="w-[32px] h-[26px] text-[#3F607E] " />
              </div>
            )}

            <div className="">
              <h1 className="font-bold">Level</h1>
              <h1 className="font-extrabold text-[22px]">
                {level !== null ? level : "..."}
              </h1>
            </div>

            {session.personal.role !== "readonly" && (
              <div
                className="w-[48px] h-[46px] -[8px] flex items-center justify-center border-2 border-[#3F607E]  bg-[#3F607E] rounded-[6px] cursor-pointer"
                onClick={() =>
                  handleModal(
                    `You are moving to Level ${level + 1}`,
                    "General queue will be restarted.",
                    "Confirm",
                    handleConfirmationOfMovingLevel2
                  )
                }
              >
                <ArrowRight className="w-[32px] h-[26px] text-white" />
              </div>
            )}
          </div>
          {session.personal.role !== "readonly" && (
            <div className="w-[30%] flex self-start  justify-end">
              <button
                className=" w-[123px] h-[37px] border-2 border-[#3F607E] rounded-[49px] font-bold text-[18px] text-[#3F607E] cursor-pointer self-start"
                onClick={() =>
                  handleModal(
                    "You are about to clear all",
                    null,
                    "Clear All",
                    clearAll
                  )
                }
              >
                Clear All
              </button>
            </div>
          )}
        </div>

        <div
          className={`flex items-center w-full rounded-[9px] px-2 py-2 cursor-pointer ${
            isUrgentMicrophone ? theme["mic"] : theme["noMic"]
          }`}
          // onClick={() => setIsUrgentMicrophone(!isUrgentMicrophone)}
        >
          {/* <Warning
            className={` w-[10%] h-[40%] mr-4 ${
              isUrgentMicrophone ? theme["micIcon"] : theme["noMicIcon"]
            }`}
            weight="fill"
          />
          <p className="font-bold text-[22px] mr-2">Urgent Recognition</p>
          <p className=" text-[18px] rounded-full flex items-center justify-center w-[30px] h-[30px] border-2 border-_dark font-extrabold">
            {
              participantQueueData.filter((a, b) => {
                return a.type === "urgent";
              })[0].length
            }
          </p>

          {isUrgentMicrophone &&
            (participantQueueData.filter((a, b) => {
              return a.type === "urgent";
            })[0].length > 0 ? (
              <>
                <div
                  onClick={() => {
                    allowToSpeak({
                      position: participantQueueData.filter((a, b) => {
                        return a.type === "urgent";
                      })[0].mics[0]?.position,
                      reason: "urgent",
                      addedAt: participantQueueData.filter((a, b) => {
                        return a.type === "urgent";
                      })[0].mics[0]?.addedAt,
                    });
                  }}
                  className="mr-2 cursor-pointer w-[358px] min-w-[358px] h-[60px]  ml-10  bg-white rounded-[6px] flex items-center px-3 text-_dark"
                >
                  <div className="flex items-center space-x-1 w-[50%] justify-center">
                    <Microphone weight="fill" className="w-[30px] h-[30px]" />
                    <div className="font-bold text-[32px]">
                      {
                        participantQueueData.filter((a, b) => {
                          return a.type === "urgent";
                        })[0].mics[0]?.position
                      }
                    </div>
                  </div>
                  <div className="w-[50%] flex justify-end">
                    <button className="w-[100px] h-[37px] border-2 border-[#D9AF57] rounded-[49px] font-bold text-[18px] text-[#D9AF57] cursor-pointer ">
                      next
                    </button>
                  </div>
                </div>
                {participantQueueData.map((element, index) => {
                  if (element.type !== "urgent") {
                    return;
                  }
                  return (
                    <MicParlimainterian
                      isUrgent={true}
                      isPressed={buttonPressedFirst.pressed}
                      theme={buttonPressedFirst.pressedBy}
                      data={element}
                      key={index}
                      allowToSpeak={allowToSpeak}
                    />
                  );
                })}
              </>
            ) : (
              <div className="w-[358px] min-w-[358px] h-[60px]  ml-10  bg-white rounded-[6px] flex items-center px-3 text-_dark">
                <div className="flex items-center space-x-1 w-[100%] justify-center">
                  <Microphone
                    weight="fill"
                    className="mr-5 opacity-50 w-[30px] h-[30px]"
                  />
                  <div className="font-bold text-[20px] opacity-50">
                    No Request...
                  </div>
                </div>
              </div>
            ))} */}
          {participantQueueData.map((element, index) => {
            if (element.type !== "urgent") {
              return;
            }
            return (
              <MicParlimainterian
                isUrgent={true}
                isReadOnly={session.personal.role === "readonly"}
                isPressed={buttonPressedFirst.pressed}
                theme={buttonPressedFirst.pressedBy}
                data={element}
                key={index}
                allowToSpeak={allowToSpeak}
                level={level}
                clearQueues={clearQueues}
                handleModal={handleModal}
              />
            );
          })}
        </div>

        {participantQueueData.map((element, index) => {
          if (element.type === "urgent") {
            return;
          }
          return (
            <MicParlimainterian
              isPressed={buttonPressedFirst.pressed}
              isReadOnly={session.personal.role === "readonly"}
              theme={buttonPressedFirst.pressedBy}
              data={element}
              key={index}
              allowToSpeak={allowToSpeak}
              level={level}
              clearQueues={clearQueues}
              handleModal={handleModal}
            />
          );
        })}
      </div>
    </>
  );
};
export default Parliamentarian;
