import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CredentialRow from "../components/CredentialRow";
import {
  CONSTANT,
  setMessage,
  resetMessage,
  checkLoginFromAdmin,
} from "../CONSTANT";
import { Link, useNavigate } from "react-router-dom";
import UserData from "../contexts/UserData";

const Credentials = () => {
  const { session, setSession } = useContext(UserData);
  let navigate = useNavigate();

  useEffect(() => {
    if (checkLoginFromAdmin()) {
      navigate("/");
    }
  }, []);
  const [allCredentialsData, setAllCredentialsData] = useState([]);
  const [isAddCredentialsForm, setIsAddCredentialsForm] = useState(false);
  const [editCredentials, setEditCredentials] = useState(false);

  useEffect(() => {
    fetchAllCredentialsData();
  }, []);

  const fetchAllCredentialsData = async () => {
    await axios
      .get(CONSTANT.server + "credentials/view")
      .then((responce) => {
        setAllCredentialsData(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // setup credentials form data for modal
  let __initCredentials__ = {
    name: "",
    role: "mic",
    password: "",
  };
  const [credentials, setCredentials] = useState(__initCredentials__);

  // On pressing the update button , it will setup the credentials automatically into the form modal

  const handleEditCredentialButton = (id, payload) => {
    // set edit form view  to true in order to show the credentials data into the form by using id
    setEditCredentials(true);

    // assigning the credentials of the modal
    setCredentials({
      ...payload,
      password: "",
    });

    // open the form modal window having credentials of that particular user
    setIsAddCredentialsForm(true);
  };

  const clearForm = () => {
    setCredentials(__initCredentials__);
    setEditCredentials(false);
  };

  // Handle credentials form on click of save button of modal
  const handleSubmitAddCredentials = async (e) => {
    // Some logic for handling the form data (New Credentials)
    await axios
      .post(CONSTANT.server + "credentials/insert", {
        ...credentials,
      })
      .then((responce) => {
        if (responce.status === 200) {
          let res = responce.data;
          if (res.message) {
            setMessage(res.message, "red-500");
          } else {
            setMessage("Credentials added successfully!", "green-500");
            clearForm();
            fetchAllCredentialsData();
            setIsAddCredentialsForm(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle credentials form on click of save button of modal
  const handleSubmitUpdateCredentials = async () => {
    // Some logic for handling the form data (New Credentials)
    if (credentials._id !== "") {
      await axios
        .post(CONSTANT.server + "credentials/update/" + credentials._id, {
          name: credentials.name,
          role: credentials.role,
          password: credentials.password,
        })
        .then((responce) => {
          if (responce.status === 200) {
            let res = responce.data;
            if (res.message) {
              setMessage(res.message, "red-500");
            } else {
              setMessage("Credentials updated successfully!", "green-500");
              clearForm();
              fetchAllCredentialsData();
              setIsAddCredentialsForm(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Handle delete button of credentials

  const handleDeleteCredentialButton = async (id) => {
    // Some logic to handle Delete credentials by using id
    await axios
      .post(CONSTANT.server + "credentials/delete/" + id, {})
      .then((responce) => {
        if (responce.status === 200) {
          fetchAllCredentialsData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeCredentials = (e, change = false) => {
    let temp = {
      ...credentials,
      [e.target.name]: e.target.value,
    };
    if (change) {
      temp["name"] = "";
    }
    setCredentials(temp);
  };

  return (
    <div className="spacing">
      <button
        className="border-2 border-[#D9AF57] rounded-full mb-5  h-[39px] flex items-center justify-center ml-auto py-[7px] px-[11px] font-extrabold text-[#D9AF57] my-1"
        onClick={() => {
          clearForm();
          setIsAddCredentialsForm(true);
        }}
      >
        Add Credentials
      </button>

      {isAddCredentialsForm && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  "
          style={{
            background: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="p-8 relative z-  text-left bg-white w-[612px] h-[392px] rounded-[26px] flex flex-col justify-between "
            style={{
              boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
            }}
          >
            <h1 className="font-bold text-[22px] ">
              {editCredentials ? "Edit Credentials" : "Create new credentials"}
            </h1>

            <input
              type={credentials.role === "mic" ? "number" : "text"}
              placeholder="Name"
              className="border w-full  rounded-[16px]  p-4"
              name="name"
              disabled={credentials.name === "superadmin"}
              value={credentials.name}
              onChange={changeCredentials}
            />
            <input
              type="password"
              placeholder={
                editCredentials
                  ? "New Password (Leave empty to keep same password)"
                  : "Password"
              }
              className="border w-full  rounded-[16px]  p-4"
              name="password"
              value={credentials.password}
              onChange={changeCredentials}
            />

            <select
              id="countries"
              className=" border  rounded-[16px]  border-gray-200 text-gray-900 text-sm  focus:ring-gray-500 focus:border-gray-500 block w-full p-4 "
              name="role"
              disabled={credentials.name === "superadmin"}
              value={credentials.role}
              onChange={(e) => {
                changeCredentials(e, true);
              }}
            >
              <option disabled>Choose the role</option>
              <option value="admin">Parliamentarian/Admin</option>
              <option value="readonly">Parliamentarian (Read Only)</option>
              <option value="mic">Mic</option>
            </select>

            <p className="my-2" id="error"></p>

            <div className="flex justify-end items-center space-x-5 text-[22px]">
              <button
                className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
                onClick={() => setIsAddCredentialsForm(false)}
              >
                Cancel
              </button>
              <button
                className={`w-[186px] h-[48px] bg-[#D9AF57] transition duration-700 ease-in-out rounded-[49px] text-white font-extrabold cursor-${
                  (credentials.name !== "" &&
                    credentials.password !== "" &&
                    credentials.role !== "") ||
                  editCredentials
                    ? "pointer"
                    : "not-allowed opacity-50"
                }`}
                onClick={
                  editCredentials
                    ? handleSubmitUpdateCredentials
                    : handleSubmitAddCredentials
                }
                disabled={
                  (credentials.name !== "" &&
                    credentials.password !== "" &&
                    credentials.role !== "") ||
                  editCredentials
                    ? false
                    : true
                }
              >
                {editCredentials ? "Edit " : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="">
        <div className="flex  items-center justify-between space-x-16 text-left border-b-2 px-2 py-1 border-gray-50 text-_dark font-bold">
          <div className=" w-[25%]">Name</div>

          <div className="w-[25%] text-left">Role</div>

          <div className="w-[25%] text-left">Date Created</div>

          <div className="w-[25%] text-center">Actions</div>
        </div>

        <div
          className="overflow-y-scroll h-full 
            max-h-[calc(100vh-180px)] "
        >
          {allCredentialsData
            .filter((credential, index) => {
              if (
                session?.personal?.name !== "superadmin" &&
                credential.name === "superadmin"
              ) {
                return false;
              }
              return true;
            })
            .map((credential, index) => {
              return (
                <CredentialRow
                  key={index}
                  data={credential}
                  current={session?.personal?.name}
                  handleEditCredentialButton={handleEditCredentialButton}
                  handleDeleteCredentialButton={handleDeleteCredentialButton}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Credentials;
