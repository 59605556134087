import {
  Info,
  Microphone,
  ThumbsDown,
  ThumbsUp,
  Warning,
  Trash,
} from "phosphor-react";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const MicParlimainterian = (props) => {
  const mics = props?.data?.mics;

  const [isFirstPressed, setIsFirstPressed] = useState(
    props?.data.type === props?.theme
  );

  useEffect(() => {
    if (props?.data.type === props?.theme) {
      setIsFirstPressed(true);
    } else {
      setIsFirstPressed(false);
    }
  }, [props]);

  // @function for styling purposes
  const toggleTheme = {
    against: "bg-[#F3514F66]",
    general: "bg-[#E9FAEA]",
    request: "bg-[#E4E9ED]",
    bg: "bg-white border-white",
    noBg: "border-[#D9D9D9]",
  };

  return (
    <div
      className={`w-full flex items-end overflow-x-${
        props.isUrgent ? "hidden" : "hidden"
      }`}
    >
      <div
        className={`w-[378px] space-y-3 p-2 min-w-[378px] rounded-[9px] ${
          isFirstPressed && toggleTheme[props.theme]
        }`}
      >
        <div className="flex items-center space-x-2">
          {icons[props?.data?.type]}

          <p className="capitalize font-bold text-[24px]">
            {props?.data?.type === "general"
              ? "General/For"
              : props?.data?.type === "against"
              ? "General/Against"
              : props?.data?.type === "request"
              ? "Request"
              : props?.data?.type === "urgent"
              ? "Urgent Recognition"
              : ""}
          </p>
          <p className=" text-[16px] rounded-full flex items-center justify-center w-[27px] h-[27px]  font-extrabold bg-[#E4E9ED]">
            {props?.data?.length}
          </p>
          {mics.length > 0 && !props.isReadOnly && (
            <Trash
              weight="fill"
              className="w-[30px] h-[30px] cursor-pointer text-[#F3514F]"
              onClick={() =>
                props.handleModal(
                  "Are you sure?",
                  `You are about to clear ${
                    props?.data?.type === "general"
                      ? "General/For"
                      : props?.data?.type === "against"
                      ? "General/Against"
                      : props?.data?.type === "request"
                      ? "Request"
                      : props?.data?.type === "urgent"
                      ? "Urgent Recognition"
                      : ""
                  }'s level ${props.level} queue.`,
                  "Clear",
                  () => {
                    props.clearQueues(props?.data?.type);
                  }
                )
              }
            />
          )}
        </div>
        {mics.length > 0 ? (
          <div
            className={`w-[358px] cursor-pointer min-w-[358px] h-[68px] rounded-[6px] flex items-center px-3 text-_dark ${
              isFirstPressed ? toggleTheme["bg"] : toggleTheme["noBg"]
            } ${props.isUrgent ? "bg-white " : "border-2 "} ${
              props.isReadOnly && "pointer-events-none"
            }`}
            onClick={() => {
              props.allowToSpeak({
                position: mics[0]?.position,
                reason: props?.data?.type,
                addedAt: mics[0]?.addedAt,
              });
            }}
          >
            <div className="flex items-center space-x-1 w-[50%] justify-center">
              <Microphone weight="fill" className="w-[30px] h-[30px]" />
              <div className="font-bold text-[32px] ">{mics[0]?.position}</div>
            </div>
            {!props.isReadOnly && (
              <div className="w-[50%] flex justify-end">
                <button className="w-[100px] h-[37px] border-2 border-[#D9AF57] rounded-[49px] font-bold text-[18px] text-[#D9AF57] cursor-pointer ">
                  next
                </button>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`w-[358px] min-w-[358px] h-[68px] border-2  rounded-[6px] flex items-center px-3 text-_dark ${
              isFirstPressed ? toggleTheme["bg"] : toggleTheme["noBg"]
            }`}
          >
            <div className="flex items-center space-x-1 w-[100%] justify-center">
              <Microphone
                weight="fill"
                className="mr-5 opacity-50 w-[30px] h-[30px]"
              />
              <div className="font-bold text-[20px] opacity-50">
                No Request...
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`flex items-center py-2 space-x-3 w-full overflow-x-${
          props.isUrgent ? "hidden mr-2" : "hidden"
        }`}
      >
        {/* (index != 0 && index < 5 && !props.isUrgent) ||
            (index != 0 && props.isUrgent) */}
        {mics.map((element, index) => {
          if (index != 0 && index < 5) {
            return (
              <div
                key={index}
                className={`${
                  props.isUrgent
                    ? "w-[25%] bg-white"
                    : "w-[25%] border-2 border-[#D9D9D9]"
                } h-[68px] ml-2 rounded-[6px] flex items-center justify-center px-3 text-_dark`}
              >
                <div className="flex items-center space-x-1  justify-center">
                  <Microphone weight="fill" className="w-[30px] h-[30px]" />
                  <div className="font-bold text-[32px]">
                    {element.position}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const icons = {
  general: (
    <>
      <ThumbsUp className="w-[23px] h-[23px] text-[#5fd86a]" weight="fill" />{" "}
    </>
  ),
  against: (
    <>
      <ThumbsDown className="w-[23px] h-[23px] text-[#F3514F]" weight="fill" />
    </>
  ),
  request: (
    <>
      <Info className="w-[23px] h-[23px] text-[#3F607E]" weight="fill" />{" "}
    </>
  ),
  urgent: (
    <>
      <Warning className="w-[28px] h-[28px] text-[#FFD600]" weight="fill" />
    </>
  ),
};

export default MicParlimainterian;
