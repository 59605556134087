import React, { useEffect, useState, useContext, useRef } from "react";
import MicSpeakCard from "../components/MicSpeakCard";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANT, checkLoginFromMic } from "../CONSTANT";
import openSocket from "socket.io-client";
import UserData from "../contexts/UserData";
import {
  Info,
  NumberOne,
  ThumbsDown,
  ThumbsUp,
  User,
  Warning,
} from "phosphor-react";
import MicModal from "../components/MicModal";

const APLHABETS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const Home = () => {
  const [SOCKET, SETSOCKET] = useState(null);
  const [calls, setCalls] = useState([]);

  const { session, setSession } = useContext(UserData);
  let navigate = useNavigate();
  const [activeCard, setActiveCard] = useState("");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({
    modal: "",
    type: "",
    position: "",
    isCancelFinal: false,
  });

  const [motiveCards, setMotiveCards] = useState(motiveData);

  // a pop up with a 4 seconds time out will appear confirming participation.
  useEffect(() => {
    if (modal && modalData.modal == "confirmed") {
      setTimeout(() => {
        setModal(false);
      }, 4000);
    }
  }, [modal]);

  useEffect(() => {
    if (checkLoginFromMic()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (modalData.modal === "confirmed") {
      let next_position =
        session.personal.name +
        APLHABETS[
          APLHABETS.indexOf(position.current[position.current.length - 1]) + 1
        ];
      SOCKET.emit("INCOMING_REQUEST", {
        addedAt: Date.now(),
        position: position.current,
        reason: modalData.type,
        _id: session.personal._id,
        next_position: next_position,
      });
      position.current = next_position;
    }
    if (modalData.modal === "cancel" && modalData.isCancelFinal) {
      SOCKET.emit("DISCARD_INCOMING_REQUEST", {
        position: modalData.position,
        _id: session.personal._id,
      });
    }
  }, [modalData]);

  // Function for handle motive Submission event
  const handleSubmit = (card) => {
    // Setup data of pop up modal in order to display the selected motive confirmation  message for 4 seconds
    setModalData({
      modal: "confirmed",
      type: card,
      position: position.current,
    });

    // Reseting the active motive card in order to hide the background colar and borders
    setActiveCard("");

    setModal(true);
  };

  // function for handling Cancel button (Queue)
  const handleCancel = (call) => {
    // Setup data of modal in order to display the cancel participation motive
    console.log(call);
    setModalData({
      modal: "cancel",
      type: call.reason,
      position: call.position,
      isCancelFinal: false,
    });

    setModal(true);
  };

  // Handle cancel participation after comfirmation in Modal
  const handleCancelConfirmation = () => {
    // some logic to handle cancel participation
    // ========
    // ========
    // ========
    setModalData({
      ...modalData,
      isCancelFinal: true,
    });

    // closing modal for cancel participation
    setModal(false);
  };

  // ========
  // SOCKET IO START
  // ========

  const socketConnection = useRef(null);
  const position = useRef(session.personal.name + APLHABETS[0]);

  useEffect(() => {
    SETSOCKET(openSocket(CONSTANT.server));
  }, []);

  useEffect(() => {
    if (session.isLoggedIn && SOCKET !== null) {
      position.current = session.personal.name + APLHABETS[0];
      // Set initial position

      SOCKET.on("connect", () => {
        socketConnection.current = SOCKET.id;
        SOCKET.emit("MIC_REGISTER", {
          _id: session.personal._id,
          name: session.personal.name,
          socket_id: SOCKET.id,
        });
        if (SOCKET.disconnected) {
          SOCKET.connect();
        }
      });
      // Connect to socket

      SOCKET.on("disconnect", () => {
        socketConnection.current = "";
      });
      // Connect and update socket id on state

      SOCKET.on("UPDATE", (payload) => {
        if (payload.length > 0) {
          payload = payload.sort(function (a, b) {
            return b.addedAt - a.addedAt;
          });
          position.current = payload[0]["next_position"];
          setCalls(payload);
        } else {
          setCalls(payload);
        }
      });
      // TAKE UPDATES

      SOCKET.on("CLEAR_EVERYTHING", (payload) => {
        if (payload) {
          setCalls([]);
          position.current = session.personal.name + APLHABETS[0];
        }
      });
      // CLEARS EVERYTHING

      return () => {
        SOCKET.disconnect();
      };
      // When leaving, inform server
    }
  }, [session, SOCKET]);

  // ========
  // SOCKET IO END
  // ========

  // Test point start
  const [test, setTest] = useState(0);
  useEffect(() => {
    if (test !== 0) {
      // SOCKET.emit("TEST", "");
    }
  }, [test]);
  // Test point end

  return (
    <div className=" space-y-[50px] flex flex-col justify-between h-[calc(100vh-90px)]">
      <div className="spacing">
        <div className=" flex items-start justify-between ">
          {motiveCards.map((card, index) => {
            return (
              <MicSpeakCard
                key={index}
                data={card}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            );
          })}
        </div>
      </div>
      {activeCard !== "" && (
        <button
          type="submit"
          id="default-search-8"
          className="block h-[60px]  text-[18px] mx-auto font-extrabold outline-none text-white bg-_gold rounded-full border border-_gold focus:ring-_gold focus:border-_gold  _box_shadow w-[468px]"
          onClick={() => handleSubmit(activeCard)}
        >
          Submit
        </button>
      )}
      {modal && (
        <MicModal
          modal={modalData.modal}
          setModal={setModal}
          type={modalData.type}
          position={modalData.position}
          func={handleCancelConfirmation}
        />
      )}
      <div className="spacing _shadow h-[220px] flex flex-col justify-center space-y-3  mt-auto">
        <h1
          className="text-left text-_slate text-[24px] font-bold "
          onClick={() => {
            setTest((old) => {
              return old + 1;
            });
          }}
        >
          Queue
        </h1>
        <div className="flex items-center space-x-4 overflow-x-auto">
          {calls
            .sort(function (a, b) {
              return a.addedAt - b.addedAt;
            })
            .map((call, index) => {
              return (
                <div className="" key={index}>
                  <div className="flex items-center space-x-1 justify-center">
                    {call.reason === "general" ? (
                      <ThumbsUp
                        weight="fill"
                        className={`text-${
                          call.isFrozen ? "[#7B8484] opacity-50" : "[#5FD86A]"
                        } w-[46px] h-[46px]`}
                      />
                    ) : call.reason === "against" ? (
                      <ThumbsDown
                        weight="fill"
                        className={`text-${
                          call.isFrozen ? "[#7B8484] opacity-50" : "[#F3514F]"
                        } w-[46px] h-[46px]`}
                      />
                    ) : call.reason === "request" ? (
                      <Info
                        weight="fill"
                        className={`text-${
                          call.isFrozen ? "[#7B8484] opacity-50" : "[#3F607E]"
                        } w-[46px] h-[46px]`}
                      />
                    ) : (
                      <Warning
                        weight="fill"
                        className={`text-${
                          call.isFrozen ? "[#7B8484] opacity-50" : "[#FFD600]"
                        } w-[48px] h-[48px]`}
                      />
                    )}
                    <p className="w-[36px] h-[36px] bg-[#7B8484] text-white font-black flex items-center justify-center rounded-full text-[26px]">
                      {call.position.slice(call.position.length - 1)}
                    </p>
                  </div>
                  <button
                    className="w-[112px] border-2 border-[#3F607E] text-[#3F607E] py-[5px] px-[11px] rounded-[49px] mt-3 text-[22px] font-bold"
                    onClick={() => handleCancel(call)}
                  >
                    Cancel
                  </button>
                </div>
              );
            })}
          {calls.length <= 0 ? (
            <h1 className="text-[20px] font-bold opacity-50">No Request...</h1>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const motiveData = [
  {
    id: uuidv4(),
    icon: (
      <ThumbsUp className="w-[48px] h-[48px] text-[#5fd86a]" weight="fill" />
    ),
    title: "General/",
    title_2: "For",
    desc: "seeking recognition, speak for the current item, call the question, make a motion/amendment...",
    theme: "general",
  },
  {
    id: uuidv4(),
    icon: (
      <ThumbsDown className="w-[48px] h-[48px] text-[#F3514F]" weight="fill" />
    ),
    title: "General/",
    title_2: "Against",
    desc: "seeking recognition, speak against the current item, call the question, make a motion/amendment...",
    theme: "against",
  },
  {
    id: uuidv4(),
    icon: <Info className="w-[48px] h-[48px] text-[#3F607E]" weight="fill" />,
    title: "Request",
    title_2: null,
    desc: "interrupting motions, inquiry/request for information, withdraw a motion, question of privilege...",
    theme: "request",
  },

  {
    id: uuidv4(),
    icon: (
      <Warning className="w-[48px] h-[48px] text-[#FFD600]" weight="fill" />
    ),
    title: "Urgent",
    title_2: "Recognition",
    desc: "only for point of order, objection, appeal, or division.",
    theme: "urgent",
  },
];

export default Home;
