import { Info, ThumbsDown, ThumbsUp, Warning } from "phosphor-react";
import React from "react";

const MicModal = (props) => {
  if (props.modal == "confirmed") {
    return (
      <div
        className="fixed top-[-50px] left-0 w-screen h-screen flex items-center justify-center "
        style={{
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <div
          className="p-8 relative z-  text-center bg-white w-[468px] h-[280px] rounded-[26px] flex flex-col justify-between "
          style={{
            boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
          }}
        >
          <h1 className="text-[32px] font-bold">Confirmed!</h1>
          <h1 className="text-[80px] font-bold">{props.position}</h1>

          <div className="flex items-center justify-center space-x-3">
            {icons[props.type]}
          </div>
        </div>
      </div>
    );
  } else if (props.modal == "cancel") {
    return (
      <div
        className="fixed top-[-50px] left-0 w-screen h-screen flex items-center justify-center "
        style={{
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <div
          className="p-8 relative z-  text-center bg-white w-[468px] h-[280px] rounded-[26px] flex flex-col justify-between "
          style={{
            boxShadow: "0px 2px 16px rgba(107, 107, 107, 0.21)",
          }}
        >
          <h1 className="text-[32px] font-bold">Cancel Participation for</h1>
          <div className="flex items-center justify-center space-x-3">
            {cancelIcons[props.type]}
            <p className="text-[37px] flex items-center justify-center w-[60px] h-[60px]  rounded-full   font-black  text-white bg-[#7B8484]">
              {props.position[props.position.length - 1]}
            </p>
          </div>

          <div className="flex justify-center items-center space-x-5 text-[20px] w-full">
            <button
              className="w-[186px] h-[48px] text-[#D9AF57] font-extrabold cursor-pointer border-[3px] border-transparent hover:border-[#D9AF57] rounded-[49px] transition ease-in-out"
              onClick={() => props.setModal(false)}
            >
              never mind
            </button>
            <button
              className="w-[186px] h-[48px] bg-[#D9AF57] rounded-[49px] text-white font-extrabold cursor-pointer"
              onClick={() => props.func()}
            >
              Yes, Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
};

const cancelIcons = {
  general: (
    <>
      <ThumbsUp className="w-[69px] h-[69px] text-[#5fd86a]" weight="fill" />{" "}
    </>
  ),
  against: (
    <>
      <ThumbsDown className="w-[69px] h-[69px] text-[#F3514F]" weight="fill" />{" "}
    </>
  ),
  request: (
    <>
      <Info className="w-[69px] h-[69px] text-[#3F607E]" weight="fill" />{" "}
    </>
  ),
  urgent: (
    <>
      <Warning className="w-[69px] h-[69px] text-[#FFD600]" weight="fill" />{" "}
    </>
  ),
};

const icons = {
  general: (
    <>
      <ThumbsUp className="w-[28px] h-[28px] text-[#5fd86a]" weight="fill" />{" "}
      <p className="text-[24px] font-bold text-_dark">General/For</p>
    </>
  ),
  against: (
    <>
      <ThumbsDown className="w-[28px] h-[28px] text-[#F3514F]" weight="fill" />
      <p className="text-[24px] font-bold text-_dark">General/Against</p>
    </>
  ),
  request: (
    <>
      <Info className="w-[28px] h-[28px] text-[#3F607E]" weight="fill" />{" "}
      <p className="text-[24px] font-bold text-_dark">Request</p>
    </>
  ),
  urgent: (
    <>
      <Warning className="w-[28px] h-[28px] text-[#FFD600]" weight="fill" />
      <p className="text-[24px] font-bold text-_dark">Urgent Recongnition</p>
    </>
  ),
};

export default MicModal;
