import logo from "./logo.svg";
import "./App.css";
import Login from "./auth/Login";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Layout from "./Layouts/Layout";
import Home from "./views/Home";
import Admin from "./views/Admin";
import Parliamentarian from "./views/Parliamentarian";
import Credentials from "./views/Credentials";

function App() {
   return (
      <div className="App">
         <Router>
            <Routes>
               <Route
                  path="/mic"
                  exact
                  element={
                     <Layout mic="true">
                        <Home />
                     </Layout>
                  }
               />

               <Route
                  path="/admin"
                  exact
                  element={
                     <Layout>
                        <Admin />
                     </Layout>
                  }
               />
               <Route
                  path="/credentials"
                  exact
                  element={
                     <Layout>
                        <Credentials />
                     </Layout>
                  }
               />
               <Route
                  path="/parliamentarian"
                  exact
                  element={
                     <Layout>
                        <Parliamentarian />
                     </Layout>
                  }
               />

               <Route path="/" element={<Login />} />
               <Route path="*" element={<Login />} />
            </Routes>
         </Router>
      </div>
   );
}

export default App;
