export const CONSTANT = {
  server: "https://prqsystem.com/", // CHANGE WITH YOUR BACKEND LINK (/ is MUST IN END)
  client: "https://prqsystem.com/", // CHANGE WITH YOUR FRONTEND LINK (/ is MUST IN END)
};
// export const CONSTANT = {
//   server: "http://localhost:4000/", // CHANGE WITH YOUR BACKEND LINK (/ is MUST IN END)
//   client: "http://localhost:3000/", // CHANGE WITH YOUR FRONTEND LINK (/ is MUST IN END)
// };

export const originalPanel = () => {
  let __temp__ = [false, "/"];
  let __temp__session__ = sessionStorage.getItem("loggedin");
  try {
    let __parsed__temp__session__ = JSON.parse(__temp__session__).data;
    if (__temp__session__ && __parsed__temp__session__) {
      __temp__[0] = true;
      if (__parsed__temp__session__.role === "mic") {
        __temp__[1] = "/mic";
      } else {
        __temp__[1] = "/parliamentarian";
      }
    }
  } catch (e) {
    // Not Logged In
    __temp__[1] = "/";
  }
  return __temp__;
};

export const checkLoginFromLogin = () => {
  return sessionStorage.getItem("loggedin") &&
    JSON.parse(sessionStorage.getItem("loggedin")).data
    ? true
    : false;
};

export const getRole = () => {
  return JSON.parse(sessionStorage.getItem("loggedin")).data.role;
};

export const checkLoginFromMic = () => {
  return sessionStorage.getItem("loggedin") &&
    JSON.parse(sessionStorage.getItem("loggedin")).data.role === "mic"
    ? false
    : true;
};

export const checkLoginFromAdmin = (isReadOnlyAllowed = false) => {
  return sessionStorage.getItem("loggedin") &&
    (JSON.parse(sessionStorage.getItem("loggedin")).data.role === "admin" ||
      (JSON.parse(sessionStorage.getItem("loggedin")).data.role ===
        "readonly" &&
        isReadOnlyAllowed))
    ? false
    : true;
};

export const Loader = (extra = "") => {
  return (
    <div class={`spinner-grow ${extra}`} role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  );
};

export const setMessage = (text, color) => {
  let error = document.getElementById("error");
  error.innerHTML = text;
  error.classList.add("text-" + color);
  error.style.display = "block";
};

export const resetMessage = () => {
  let error = document.getElementById("error");
  error.innerText = "";
  error.style.display = "none";
  error.classList.remove("text-red-500");
  error.classList.remove("text-green-500");
};

export const isMessage = () => {
  let error = document.getElementById("error");
  if (error.style.display === "none") {
    return false;
  }
  return true;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
