import { Eye, ThumbsDown, ThumbsUp, Warning } from "phosphor-react";
import React, { useState } from "react";
import { capitalizeFirstLetter } from "../CONSTANT";

const CredentialRow = (props) => {
  return (
    <>
      <div className="space-x-16 text-left border-b-2 py-[6px]  border-gray-50 flex items-center justify-between px-2">
        <div className=" w-[25%] text-_dark text-[14px]  text-left font-light  ">
          {props?.data?.name}{" "}
          {props.current === props?.data?.name ? (
            <span className="text-_dark opacity-75">(current)</span>
          ) : (
            ""
          )}
        </div>

        <div className="  w-[25%]  text-_dark text-[14px]   1 font-light  text-left">
          {capitalizeFirstLetter(
            props?.data?.role === "admin"
              ? "Parliamentarian/Admin"
              : props?.data?.role === "readonly"
              ? "Parliamentarian (Read Only)"
              : props?.data?.role
          )}
        </div>

        <div className="   w-[25%]  text-_dark text-[14px]   text-left ">
          {new Date(props?.data?.addedAt).toLocaleString()}
        </div>

        <div className="  w-[25%] flex items-center justify-center space-x-4 ">
          <button
            className=" cursor-pointer py-[2x] px-[4px] flex items-center justify-center w-[76px] h-[30px] bg-[#E9FAEA] border rounded-[6px] border-[#5FD86A]"
            onClick={() =>
              props.handleEditCredentialButton(props?.data?._id, props?.data)
            }
          >
            Update
          </button>
          {props?.data?.name !== "superadmin" ? (
            <button
              className=" cursor-pointer py-[2x] px-[4px] flex items-center justify-center w-[76px] h-[30px] bg-[#FDE7E6] border rounded-[6px] border-[#F3514F]"
              onClick={() =>
                props.handleDeleteCredentialButton(props?.data?._id)
              }
            >
              Delete
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CredentialRow;
