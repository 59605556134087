import React from "react";

const UserData = React.createContext({
  data: {
    personal: {
      _id: "",
      name: "",
      role: "",
      addedAt: "",
    },
    isLoggedIn: false,
  },
  setData: () => {},
});

export default UserData;
