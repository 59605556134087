import React from "react";

const MicSpeakCard = (props) => {
  // set active card in order to show the selected card background and border radius style and also for passing the selected active motive to the modal window
  const handleMotiveCardClick = () => {
    props.setActiveCard((old) => {
      return old !== "" ? "" : props.data.theme;
    });
  };

  // styling for removed the background colors and border radius
  const deactive = "border-[1px] border-[#A6A6A6] bg-transparent";

  // Setup different styles (backgrounds colors and border radius) for every motive card
  const colors = {
    general: "activeCard border-[4px] border-[#5FD86A] bg-[#E9FAEA]",
    against: "activeCard border-[4px] border-[#F3514F] bg-[#FDE7E6]",
    request: "activeCard border-[4px] border-[#3F607E] bg-[#E4E9ED]",
    urgent: "activeCard border-[4px] border-[#FFD600] bg-[#FFF9DB]",
  };

  const styles = {
    general: ` ${props.activeCard == "general" ? colors["general"] : deactive}`,
    against: ` ${props.activeCard == "against" ? colors["against"] : deactive}`,
    request: ` ${props.activeCard == "request" ? colors["request"] : deactive}`,
    urgent: ` ${props.activeCard == "urgent" ? colors["urgent"] : deactive}`,
  };

  return (
    <div className="w-[222px] cursor-pointer">
      <div
        className={` h-[178px]  flex items-center justify-center flex-col space-y-1 rounded-[24px] mb-4 transition ease-in ${
          styles[props.data.theme]
        } ${props.data.theme + "_"}`}
        onClick={(e) => handleMotiveCardClick()}
      >
        {" "}
        {props.data.icon}
        <div className="">
          <h1 className="font-extrabold text-_dark text-[28px] ">
            {props.data.title}
          </h1>
          {props.data.title_2 && (
            <h1 className="font-extrabold text-_dark text-[28px] py-0 my-0">
              {props.data.title_2}
            </h1>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-1 ">
        <p className="font-semibold text-[14px]">{props.data.desc}</p>
      </div>
    </div>
  );
};

export default MicSpeakCard;
