import { Microphone, SignOut } from "phosphor-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicModal from "./DynamicModal";

const Navbar = (props) => {
  let navigate = useNavigate(0);
  const [isModal, setIsModal] = useState(false);

  // Handling of Exit button modal
  const handleExitButton = () => {
    // exit logic
    sessionStorage.removeItem("loggedin");
    props.setSession(props.__init_session);
    setIsModal(false);
    navigate("/");
    // Closing the exit modal window
  };

  return (
    <>
      {isModal && (
        <DynamicModal
          title="Do you want to exit?"
          desc={null}
          btnText="Exit"
          setIsModal={setIsModal}
          func={handleExitButton}
        />
      )}
      <nav className="flex items-center justify-between spacing py-4 ">
        <div className="logo">
          <h1 className="font-extrabold text-[24px] text-[#1C2F3B]">PRQ</h1>
        </div>
        <div className="space-x-[32px] flex items-center justify-end">
          <div className="flex justify-between items-center px-[16px] py-[2px] space-x-[8px] w-fit h-[34px] border-[1px] border-_dark rounded-full ">
            {" "}
            <Microphone
              className="w-[24px] h-[24px] text-_dark"
              weight="fill"
            />
            <p className="font-normal text-[20px]">
              Mic {props?.session?.personal?.name}
            </p>
          </div>
          <div
            className="flex cursor-pointer justify-center items-center  space-x-[8px]  "
            onClick={() => setIsModal(true)}
          >
            {" "}
            <SignOut className="w-[24px] h-[24px] text-_dark" weight="fill" />
            <p className="font-normal text-[20px]">Exit</p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
