import {
  Download,
  Microphone,
  SignOut,
  User,
  UserList,
  Wrench,
  ClockClockwise,
  MicrosoftExcelLogo,
} from "phosphor-react";
import React, { useEffect, useState, useRef } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import openSocket from "socket.io-client";
import { CONSTANT, checkLoginFromAdmin } from "../CONSTANT";
import DynamicModal from "./DynamicModal";
import axios from "axios";

const AdminNavbar = (props) => {
  const [SOCKET, SETSOCKET] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const navigate = useNavigate();
  const loc = useLocation();
  const csvInstance = useRef();

  useEffect(() => {
    fetchAllRecordsData();
  }, []);

  const [micsData, setMicsData] = useState([
    {
      position: "",
      level: "",
      reason: "",
      addedAt: "",
      hasSpoke: "",
      isNote: "",
      note: "",
    },
  ]);

  const [csvData, setCsvData] = useState([]);

  const fetchAllRecordsData = async (download = false) => {
    axios
      .get(CONSTANT.server + "records/view")
      .then((responce) => {
        if (responce.data.length > 0) {
          let temp = responce.data.map((a, b) => {
            return {
              position: a.position,
              level: a.level,
              reason:
                a.reason === "general"
                  ? "general/for"
                  : a.reason === "against"
                  ? "general/against"
                  : a.reason,
              addedAt: a.addedAt,
              hasSpoke: a.hasSpoke,
              note: a.note,
            };
          });
          setMicsData(temp);
          if (download) {
            setCsvData(temp);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (csvData.length > 0 && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        // console.log("DOWNLOADING LATEST DATA", csvData[0]);
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  // ========
  // SOCKET IO START
  // ========

  const socketConnection = useRef(null);

  useEffect(() => {
    SETSOCKET(openSocket(CONSTANT.server));
  }, []);

  useEffect(() => {
    if (SOCKET !== null) {
      SOCKET.on("connect", () => {
        socketConnection.current = SOCKET.id;
        SOCKET.emit("ADMIN_NAVBAR_REGISTER", {
          socket_id: SOCKET.id,
        });
        if (SOCKET.disconnected) {
          SOCKET.connect();
        }
      });
      // Connect to socket

      SOCKET.on("disconnect", () => {
        socketConnection.current = "";
      });
      // Connect and update socket id on state

      SOCKET.on("ADMIN_UPDATE", (payload) => {
        fetchAllRecordsData();
      });
      // TAKE UPDATES

      return () => {
        SOCKET.disconnect();
      };
      // When leaving, inform server
    }
  }, [SOCKET]);

  // ========
  // SOCKET IO END
  // ========

  // const [micsData, setMicsData] = useState([]);

  // Display admin or parliamentarian screens by using header Switch button
  const handleNavigationBar = (isAdminLogin) => {
    // If switch button showing admin it  will redirect user to parliamentarian screen
    if (isAdminLogin) {
      props.setisAdmin(false);
      navigate("/parliamentarian");
    }
    // If switch button showing parliamentarian it  will redirect user to admin screen
    else {
      props.setisAdmin(true);
      navigate("/admin");
    }
  };

  // Handling of Exit button modal
  const handleExitButton = () => {
    // exit logic
    sessionStorage.removeItem("loggedin");
    props.setSession(props.__init_session);
    setIsModal(false);
    navigate("/");
    // Closing the exit modal window
    setIsModal(false);
  };

  // Handling of Restore button modal
  const handleRestoreButton = () => {
    // exit logic
    SOCKET.emit("RESTORE_EVERYTHING", true);
    setIsRestore(false);
  };

  // some additional logics for admin and parliamentarian header screen views
  useEffect(() => {
    if (loc.pathname === "/admin" || loc.pathname === "/credentials") {
      props.setisAdmin(true);
    } else {
      props.setisAdmin(false);
    }
  }, []);

  // Pre Defined Styling and Spacing for admin and parliamentarian Header

  const switchTheme = {
    admin:
      "_toggle_bg w-[156px] h-[28px] rounded-l-full after:bg-[#1C2F3B] after:content-['']",
    parlamentarian:
      "bg-[#B3DBDE] w-[74px] h-[28px]  rounded-r-full after:bg-[#6DB9BF] after:content-[''] ",
    adminSpacing: "w-[168px] flex items-center justify-center mx-auto",
    parliamentarianSpacing:
      "w-[84px] flex items-center justify-center  mx-auto",
    adminBackground: "bg-[#E4E9ED]",
  };

  return (
    <>
      {isModal && (
        <DynamicModal
          title="Do you want to exit?"
          desc={null}
          btnText="Exit"
          setIsModal={setIsModal}
          func={handleExitButton}
        />
      )}

      {isRestore && (
        <DynamicModal
          title="Do you want to restore session?"
          desc={null}
          btnText="Restore"
          setIsModal={setIsRestore}
          func={handleRestoreButton}
        />
      )}

      <nav
        className={`flex items-center justify-between spacing py-4 ${
          props.isAdmin && switchTheme["adminBackground"]
        }`}
      >
        <div className="logo">
          <Link
            className="font-extrabold text-[24px] text-[#1C2F3B]"
            to={props.isAdmin ? "/admin" : "/parliamentarian"}
          >
            {props.isSuperAdmin ? (
              "Super Admin"
            ) : props.isAdmin ? (
              "Admin"
            ) : props.isReadOnly ? (
              <span>
                Parliamentarian{" "}
                <span className="text-[#F3514F]">(Read Only)</span>
              </span>
            ) : (
              "Parliamentarian"
            )}
          </Link>
        </div>
        <div className="flex items-center justify-center space-x-[32px] ">
          {props.isAdmin && (
            <>
              <button
                className="flex items-center  text-[16px] text-_dark  cursor-pointer "
                onClick={() => setIsRestore(true)}
              >
                <ClockClockwise className="w-[21px] h-[21px] mr-1" />
                Restore Session
              </button>
              <Link
                className="flex items-center  text-[16px] text-_dark  cursor-pointer "
                to="/credentials"
              >
                <UserList className="w-[21px] h-[21px] mr-1" />
                Manage Credentials
              </Link>
              <button
                className="flex items-center  text-[16px] text-_dark  cursor-pointer "
                onClick={() => fetchAllRecordsData(true)}
              >
                <MicrosoftExcelLogo className="w-[21px] h-[21px] mr-1" />
                Download CSV
              </button>
              {csvData.length > 0 && (
                <CSVLink
                  filename={`${new Date().getTime()}-ADMIN_RECORD.csv`}
                  data={csvData}
                  headers={[
                    { label: "Mic Position", key: "position" },
                    { label: "Level", key: "level" },
                    { label: "Reason", key: "reason" },
                    { label: "Timestamp", key: "addedAt" },
                    { label: "Spoke", key: "hasSpoke" },
                    { label: "Note", key: "note" },
                  ]}
                  ref={csvInstance}
                  className="hidden items-center text-dark fw-bold me-2 btn-sm btn-secondary btn-export"
                >
                  <MicrosoftExcelLogo className="w-[21px] h-[21px] mr-1" />
                  Download CSV
                </CSVLink>
              )}
            </>
          )}
          {!props.isReadOnly && (
            <div
              className={`${
                props.isAdmin
                  ? switchTheme["adminSpacing"]
                  : switchTheme["parliamentarianSpacing"]
              }`}
            >
              <label
                htmlFor="checked-toggle"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  id="checked-toggle"
                  className="sr-only peer"
                  onChange={() => handleNavigationBar(props.isAdmin)}
                  checked={props.isAdmin}
                />
                {props.isAdmin ? (
                  <div className="absolute pl-3 text-white ">
                    Parliamentarian
                  </div>
                ) : (
                  <div className="absolute w-full text-right text-[16px]  pr-3 font-bold text-[#1C2F3B]">
                    Admin
                  </div>
                )}

                <div
                  className={` ${
                    !props.isAdmin
                      ? switchTheme["parlamentarian"]
                      : switchTheme["admin"]
                  }  peer
                     peer-checked:after:left-[85%]   after:absolute  after:left-[-40%]   after:rounded-full after:h-9 after:w-9 
                     
                     after:transition-all after:top-[-5px]`}
                >
                  {props.isAdmin ? (
                    <User
                      weight="fill"
                      className="absolute z-[1000] right-[-3px] top-[4px] text-white"
                    />
                  ) : (
                    <Wrench
                      weight="fill"
                      className="absolute z-[1000] left-[-19px] top-[4px] text-[#1C2F3B]"
                    />
                  )}
                </div>

                <div className="absolute left-[-40%]"></div>
              </label>
            </div>
          )}

          <button
            className="flex justify-center items-center  space-x-[8px]  cursor-pointer "
            onClick={() => setIsModal(true)}
          >
            {" "}
            <SignOut className="w-[24px] h-[24px] text-_dark" weight="fill" />
            <p className="font-normal text-[20px]">Exit</p>
          </button>
        </div>
      </nav>
    </>
  );
};

export default AdminNavbar;
