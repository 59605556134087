import React, { useState, useEffect } from "react";
// import "./../css/Layout.css";
import { useNavigate, Link } from "react-router-dom";
import { checkLoginFromNonLogin } from "../CONSTANT";
import UserData from "../contexts/UserData";
import Navbar from "../components/Navbar";
import axios from "axios";
import AdminNavbar from "../components/AdminNavbar";

function Layout(props) {
  let navigate = useNavigate();

  //  useEffect(() => {
  //     if (checkLoginFromNonLogin()) {
  //        navigate("/login");
  //     }
  //  }, []);
  let __init_session = {
    personal: {
      _id: "",
      name: "",
      role: "",
      addedAt: "",
    },
    isLoggedIn: false,
  };
  const [session, setSession] = useState(__init_session);
  const [isAdmin, setisAdmin] = useState(true);
  useEffect(() => {
    let sessionData = JSON.parse(sessionStorage.getItem("loggedin"));
    if (sessionData) {
      setSession({
        personal: sessionData.data,
        isLoggedIn: true,
      });
    }
  }, []);
  const value = { session, setSession };
  return (
    <UserData.Provider value={value}>
      <div className="__Layout max-w-[1400px] mx-auto h-screen">
        {props.mic ? (
          <Navbar
            isLoggedIn={session.isLoggedIn}
            __init_session={__init_session}
            session={session}
            setSession={setSession}
          />
        ) : (
          <AdminNavbar
            isAdmin={isAdmin}
            isReadOnly={session.personal.role === "readonly"}
            setisAdmin={setisAdmin}
            isLoggedIn={session.isLoggedIn}
            isSuperAdmin={session.personal.name === "superadmin"}
            __init_session={__init_session}
            setSession={setSession}
          />
        )}

        <div className="mt-5">{props.children}</div>
      </div>
    </UserData.Provider>
  );
}
export default Layout;
